export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type Advocate = {
  __typename?: 'Advocate';
  activities?: Maybe<Array<Scalars['String']['output']>>;
  contributions?: Maybe<Scalars['String']['output']>;
  coreValues?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  criticalAdvocacyChange?: Maybe<Scalars['String']['output']>;
  environmentalChange?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inspirations?: Maybe<Scalars['String']['output']>;
  localBeach?: Maybe<Scalars['String']['output']>;
  profile: Profile;
  updatedAt: Scalars['Date']['output'];
};

export type Ambassador = {
  __typename?: 'Ambassador';
  createdAt: Scalars['Date']['output'];
  educationalPursuits?: Maybe<Scalars['String']['output']>;
  environmentalChange?: Maybe<Scalars['String']['output']>;
  favOceanReads?: Maybe<Scalars['String']['output']>;
  futureLegacy?: Maybe<Scalars['String']['output']>;
  guidance?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inspirationalFigures?: Maybe<Scalars['String']['output']>;
  oceanDiscovery?: Maybe<Scalars['String']['output']>;
  profile: Profile;
  school?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  youthInitiatives?: Maybe<Scalars['String']['output']>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  conversation: Conversation;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sender: User;
  text: Scalars['String']['output'];
};

export type City = {
  __typename?: 'City';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  comment: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  story: Story;
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type Conversation = {
  __typename?: 'Conversation';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  messages: Array<ChatMessage>;
  participants: Array<User>;
  updatedAt: Scalars['Date']['output'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type CreateEventInput = {
  banner: Scalars['String']['input'];
  description: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  location: LocationInput;
  start: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateGroupInput = {
  banner: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMessageInput = {
  conversationId: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type CreateStoryInput = {
  content: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  type: StoryType;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type Event = {
  __typename?: 'Event';
  banner: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  end?: Maybe<Scalars['String']['output']>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isMember?: Maybe<Scalars['Boolean']['output']>;
  location: Location;
  members: Array<EventUser>;
  membersCount?: Maybe<Scalars['Float']['output']>;
  owner: User;
  start: Scalars['String']['output'];
  status: EventStatus;
  stories: Array<Story>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export enum EventStatus {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED'
}

export type EventUser = {
  __typename?: 'EventUser';
  createdAt: Scalars['Date']['output'];
  event: Event;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type FeatureItemsInput = {
  eventIds?: InputMaybe<Array<Scalars['String']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hero?: InputMaybe<UpdateHeroInput>;
  storyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Featured homepage items */
export type FeaturedItems = {
  __typename?: 'FeaturedItems';
  events: Array<Event>;
  groups: Array<Group>;
  hero: Hero;
  stories: Array<Story>;
};

export type Group = {
  __typename?: 'Group';
  banner: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isMember?: Maybe<Scalars['Boolean']['output']>;
  media?: Maybe<Array<Media>>;
  members: Array<GroupUser>;
  membersCount?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  owner: User;
  status: GroupStatus;
  stories: Array<Story>;
  updatedAt: Scalars['Date']['output'];
};

export enum GroupStatus {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED'
}

export type GroupUser = {
  __typename?: 'GroupUser';
  createdAt: Scalars['Date']['output'];
  group: Group;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type Guardian = {
  __typename?: 'Guardian';
  advocacyForChange?: Maybe<Scalars['String']['output']>;
  conservationInitiatives?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  futureLegacy?: Maybe<Scalars['String']['output']>;
  guidanceForOthers?: Maybe<Scalars['String']['output']>;
  guidingValues?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  impactfulContributions?: Maybe<Scalars['String']['output']>;
  inspirationalInfluences?: Maybe<Scalars['String']['output']>;
  mediaPerspectives?: Maybe<Scalars['String']['output']>;
  missionPassion?: Maybe<Scalars['String']['output']>;
  profile: Profile;
  storytellingFundraising?: Maybe<Scalars['String']['output']>;
  storytellingRole?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type Hero = {
  __typename?: 'Hero';
  buttonText: Scalars['String']['output'];
  buttonUrl: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Like = {
  __typename?: 'Like';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  story: Story;
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type Location = {
  __typename?: 'Location';
  description: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  locationString: Scalars['String']['output'];
  long: Scalars['Float']['output'];
  placeId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type LocationInput = {
  description: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  locationString: Scalars['String']['input'];
  long: Scalars['Float']['input'];
  placeId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type Media = {
  __typename?: 'Media';
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addComment: Scalars['Boolean']['output'];
  approveUser: Scalars['Boolean']['output'];
  createConversation: Conversation;
  createEvent: Scalars['Boolean']['output'];
  createEventStory: Scalars['Boolean']['output'];
  createGroup: Scalars['Boolean']['output'];
  createGroupStory: Scalars['Boolean']['output'];
  createStory: Scalars['Boolean']['output'];
  deleteComment: Scalars['Boolean']['output'];
  deleteEvent: Scalars['Boolean']['output'];
  deleteGroup: Scalars['Boolean']['output'];
  deleteStory: Scalars['Boolean']['output'];
  featureHomepage: Scalars['Boolean']['output'];
  forgotPassword: Scalars['Boolean']['output'];
  joinEvent: Scalars['Boolean']['output'];
  joinGroup: Scalars['Boolean']['output'];
  leaveEvent: Scalars['Boolean']['output'];
  leaveGroup: Scalars['Boolean']['output'];
  likeStory: Scalars['Boolean']['output'];
  login: LoginResponse;
  manuallyVerifyUser: Scalars['Boolean']['output'];
  permanentlyDeleteUser: Scalars['Boolean']['output'];
  refreshAccessToken: TokenType;
  register: Scalars['Boolean']['output'];
  registerAdmin: Scalars['Boolean']['output'];
  registerAdminProfile: Scalars['Boolean']['output'];
  rejectUser: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  sendMessage: ChatMessage;
  updateComment: Scalars['Boolean']['output'];
  updateEvent: Scalars['Boolean']['output'];
  updateGroup: Scalars['Boolean']['output'];
  updateStory: Scalars['Boolean']['output'];
  updateStoryStatus: Scalars['Boolean']['output'];
  updateUser: Scalars['Boolean']['output'];
  updateUserInformation: Scalars['Boolean']['output'];
  uploadFile: Scalars['String']['output'];
  uploadMultipleFiles: Array<Scalars['String']['output']>;
  validateResetPassword: Scalars['Boolean']['output'];
  validateUserEmail: Scalars['Boolean']['output'];
};


export type MutationAddCommentArgs = {
  comment: Scalars['String']['input'];
  storyId: Scalars['String']['input'];
};


export type MutationApproveUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationCreateConversationArgs = {
  participantIds: Array<Scalars['String']['input']>;
};


export type MutationCreateEventArgs = {
  event: CreateEventInput;
};


export type MutationCreateEventStoryArgs = {
  eventId: Scalars['String']['input'];
  story: CreateStoryInput;
};


export type MutationCreateGroupArgs = {
  group: CreateGroupInput;
};


export type MutationCreateGroupStoryArgs = {
  groupId: Scalars['String']['input'];
  story: CreateStoryInput;
};


export type MutationCreateStoryArgs = {
  story: CreateStoryInput;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String']['input'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationDeleteStoryArgs = {
  storyId: Scalars['String']['input'];
};


export type MutationFeatureHomepageArgs = {
  featuredItems: FeatureItemsInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationJoinEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationJoinGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationLeaveEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationLeaveGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationLikeStoryArgs = {
  like: Scalars['Boolean']['input'];
  storyId: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  userData: LoginInput;
};


export type MutationManuallyVerifyUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationPermanentlyDeleteUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  userData: RegisterInput;
};


export type MutationRegisterAdminArgs = {
  userData: RegisterInput;
};


export type MutationRejectUserArgs = {
  reason: Scalars['String']['input'];
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  verificationId: Scalars['String']['input'];
};


export type MutationSendMessageArgs = {
  createMessageInput: CreateMessageInput;
};


export type MutationUpdateCommentArgs = {
  commentId: Scalars['String']['input'];
  update: Scalars['String']['input'];
};


export type MutationUpdateEventArgs = {
  eventId: Scalars['String']['input'];
  update: UpdateEventInput;
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['String']['input'];
  update: UpdateGroupInput;
};


export type MutationUpdateStoryArgs = {
  storyId: Scalars['String']['input'];
  update: UpdateStoryInput;
};


export type MutationUpdateStoryStatusArgs = {
  status: Scalars['String']['input'];
  storyId: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  forReview?: InputMaybe<Scalars['Boolean']['input']>;
  userUpdate: UpdateUserInput;
};


export type MutationUpdateUserInformationArgs = {
  update: UpdateUserAdminInput;
  userId: Scalars['String']['input'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadMultipleFilesArgs = {
  files: Array<Scalars['Upload']['input']>;
};


export type MutationValidateResetPasswordArgs = {
  verificationId: Scalars['String']['input'];
};


export type MutationValidateUserEmailArgs = {
  verificationId: Scalars['String']['input'];
};

/** Paginated Event Members */
export type PaginatedEventMembers = {
  __typename?: 'PaginatedEventMembers';
  count: Scalars['Float']['output'];
  data: Array<EventUser>;
};

/** Paginated Events */
export type PaginatedEvents = {
  __typename?: 'PaginatedEvents';
  count: Scalars['Float']['output'];
  data: Array<Event>;
};

/** Paginated Group Members */
export type PaginatedGroupMembers = {
  __typename?: 'PaginatedGroupMembers';
  count: Scalars['Float']['output'];
  data: Array<GroupUser>;
};

/** Paginated Groups */
export type PaginatedGroups = {
  __typename?: 'PaginatedGroups';
  count: Scalars['Float']['output'];
  data: Array<Group>;
};

/** Paginated Stories */
export type PaginatedStories = {
  __typename?: 'PaginatedStories';
  count: Scalars['Float']['output'];
  data: Array<Story>;
};

/** Paginated Users */
export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  count: Scalars['Float']['output'];
  data: Array<User>;
};

export type Profile = {
  __typename?: 'Profile';
  advocate?: Maybe<Advocate>;
  ambassador?: Maybe<Ambassador>;
  banner?: Maybe<Scalars['String']['output']>;
  behanceUrl?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  dob?: Maybe<Scalars['String']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  guardian?: Maybe<Guardian>;
  id: Scalars['ID']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['String']['output']>;
  linkedInUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  long?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Scalars['String']['output']>;
  storyteller?: Maybe<Storyteller>;
  tiktokUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  user: User;
  websiteUrl?: Maybe<Scalars['String']['output']>;
  xUrl?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  conversationsWithMessages?: Maybe<Conversation>;
  getCitiesByCountry: Array<City>;
  getConversation?: Maybe<Conversation>;
  getCountries: Array<Country>;
  getEventById: Event;
  getEventMembers: PaginatedEventMembers;
  getEvents: PaginatedEvents;
  getFeaturedItems: FeaturedItems;
  getGroupById: Group;
  getGroupMembers: PaginatedGroupMembers;
  getGroups: PaginatedGroups;
  getStories: PaginatedStories;
  getStoriesByEvent: PaginatedStories;
  getStoriesByGroup: PaginatedStories;
  getStoriesByUser: PaginatedStories;
  getStoryById: Story;
  getStoryComments: Array<Comment>;
  getStoryLikes: Array<Like>;
  getUser: User;
  getUserById: User;
  getUserEvents: PaginatedEvents;
  getUserGroups: PaginatedGroups;
  getUsers: PaginatedUsers;
  getUsersByDistance: PaginatedUsers;
  getUsersFilter: Array<UserFilter>;
  ping: Scalars['String']['output'];
};


export type QueryConversationsWithMessagesArgs = {
  conversationId: Scalars['Int']['input'];
};


export type QueryGetCitiesByCountryArgs = {
  country: Scalars['String']['input'];
};


export type QueryGetConversationArgs = {
  conversationId: Scalars['Int']['input'];
};


export type QueryGetEventByIdArgs = {
  eventId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetEventMembersArgs = {
  eventId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetEventsArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetGroupByIdArgs = {
  groupId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetGroupMembersArgs = {
  groupId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetGroupsArgs = {
  exclude?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetStoriesArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetStoriesByEventArgs = {
  eventId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetStoriesByGroupArgs = {
  groupId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetStoriesByUserArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryGetStoryByIdArgs = {
  storyId: Scalars['String']['input'];
};


export type QueryGetStoryCommentsArgs = {
  storyId: Scalars['String']['input'];
};


export type QueryGetStoryLikesArgs = {
  storyId: Scalars['String']['input'];
};


export type QueryGetUserByIdArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUserEventsArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetUserGroupsArgs = {
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetUsersArgs = {
  exclude?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<UserStatus>>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetUsersByDistanceArgs = {
  page: Scalars['Float']['input'];
  take: Scalars['Float']['input'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: UserRole;
};

export type Story = {
  __typename?: 'Story';
  comments: Array<Comment>;
  content: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  event: Event;
  featured?: Maybe<Scalars['Boolean']['output']>;
  group: Group;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  likes: Array<Like>;
  status: StoryStatus;
  type: StoryType;
  updatedAt: Scalars['Date']['output'];
  user: User;
  video?: Maybe<Scalars['String']['output']>;
};

export enum StoryStatus {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED'
}

export enum StoryType {
  Event = 'EVENT',
  Feed = 'FEED',
  Group = 'GROUP'
}

export type Storyteller = {
  __typename?: 'Storyteller';
  background?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currentInspirations?: Maybe<Scalars['String']['output']>;
  guidingAdvice?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  impactfulChanges?: Maybe<Scalars['String']['output']>;
  influentialMedia?: Maybe<Scalars['String']['output']>;
  legacyAspirations?: Maybe<Scalars['String']['output']>;
  memorableInfluences?: Maybe<Scalars['String']['output']>;
  ongoingProjects?: Maybe<Scalars['String']['output']>;
  professionalPathways?: Maybe<Scalars['String']['output']>;
  profile: Profile;
  template?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  videoUrls?: Maybe<Array<Scalars['String']['output']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  messageAdded: ChatMessage;
};


export type SubscriptionMessageAddedArgs = {
  conversationId: Scalars['Int']['input'];
};

export type TokenType = {
  __typename?: 'TokenType';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type UpdateAdvocateInput = {
  activities?: InputMaybe<Array<Scalars['String']['input']>>;
  contributions?: InputMaybe<Scalars['String']['input']>;
  coreValues?: InputMaybe<Scalars['String']['input']>;
  criticalAdvocacyChange?: InputMaybe<Scalars['String']['input']>;
  environmentalChange?: InputMaybe<Scalars['String']['input']>;
  inspirations?: InputMaybe<Scalars['String']['input']>;
  localBeach?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAmbassadorInput = {
  educationalPursuits?: InputMaybe<Scalars['String']['input']>;
  environmentalChange?: InputMaybe<Scalars['String']['input']>;
  favOceanReads?: InputMaybe<Scalars['String']['input']>;
  futureLegacy?: InputMaybe<Scalars['String']['input']>;
  guidance?: InputMaybe<Scalars['String']['input']>;
  inspirationalFigures?: InputMaybe<Scalars['String']['input']>;
  oceanDiscovery?: InputMaybe<Scalars['String']['input']>;
  school?: InputMaybe<Scalars['String']['input']>;
  youthInitiatives?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEventInput = {
  banner?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationInput>;
  start?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EventStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  banner?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GroupStatus>;
};

export type UpdateGuardianInput = {
  advocacyForChange?: InputMaybe<Scalars['String']['input']>;
  conservationInitiatives?: InputMaybe<Scalars['String']['input']>;
  futureLegacy?: InputMaybe<Scalars['String']['input']>;
  guidanceForOthers?: InputMaybe<Scalars['String']['input']>;
  guidingValues?: InputMaybe<Scalars['String']['input']>;
  impactfulContributions?: InputMaybe<Scalars['String']['input']>;
  inspirationalInfluences?: InputMaybe<Scalars['String']['input']>;
  mediaPerspectives?: InputMaybe<Scalars['String']['input']>;
  missionPassion?: InputMaybe<Scalars['String']['input']>;
  storytellingFundraising?: InputMaybe<Scalars['String']['input']>;
  storytellingRole?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateHeroInput = {
  buttonText?: InputMaybe<Scalars['String']['input']>;
  buttonUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfileInput = {
  advocate?: InputMaybe<UpdateAdvocateInput>;
  ambassador?: InputMaybe<UpdateAmbassadorInput>;
  banner?: InputMaybe<Scalars['String']['input']>;
  behanceUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  guardian?: InputMaybe<UpdateGuardianInput>;
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  long?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  storyteller?: InputMaybe<UpdateStorytellerInput>;
  tiktokUrl?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  xUrl?: InputMaybe<Scalars['String']['input']>;
  youtubeUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStoryInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStorytellerInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  currentInspirations?: InputMaybe<Scalars['String']['input']>;
  guidingAdvice?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  impactfulChanges?: InputMaybe<Scalars['String']['input']>;
  influentialMedia?: InputMaybe<Scalars['String']['input']>;
  legacyAspirations?: InputMaybe<Scalars['String']['input']>;
  memorableInfluences?: InputMaybe<Scalars['String']['input']>;
  ongoingProjects?: InputMaybe<Scalars['String']['input']>;
  professionalPathways?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  videoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateUserAdminInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<UpdateProfileInput>;
  role?: InputMaybe<UserRole>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<UpdateProfileInput>;
};

export type User = {
  __typename?: 'User';
  conversations: Array<Conversation>;
  createdAt: Scalars['Date']['output'];
  distance?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  events: Array<EventUser>;
  firstName: Scalars['String']['output'];
  groups: Array<GroupUser>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  messages?: Maybe<Array<ChatMessage>>;
  password: Scalars['String']['output'];
  profile?: Maybe<Profile>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  role: UserRole;
  status: UserStatus;
  stories: Array<Story>;
  updatedAt: Scalars['Date']['output'];
};

export type UserFilter = {
  __typename?: 'UserFilter';
  count: Scalars['Float']['output'];
  role: UserRole;
};

export enum UserRole {
  Admin = 'ADMIN',
  Advocate = 'ADVOCATE',
  Ambassador = 'AMBASSADOR',
  Guardian = 'GUARDIAN',
  Storyteller = 'STORYTELLER'
}

export enum UserStatus {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  PendingApproval = 'PENDING_APPROVAL',
  PendingVerification = 'PENDING_VERIFICATION',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED'
}
