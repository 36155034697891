import {
  Breadcrumb,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Input,
  Row,
  Spin,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Event,
  Query,
  GET_EVENT_BY_ID,
  QueryGetEventByIdArgs,
  EventToEdit,
  Location,
} from '../../../graphql';
import { useEffect, useState } from 'react';
import { LocationSearch, Text } from '../../../components';
import { EventActions } from '.';
import { colors } from '../../../utils';
import Dragger from 'antd/es/upload/Dragger';
import { UploadProps } from 'antd/es/upload';
import dayjs from 'dayjs';
import { format } from 'date-fns';

export default function SingleEvent() {
  const { id } = useParams();
  const eventToEdit = useReactiveVar(EventToEdit);
  const [event, setEvent] = useState<Event>();
  const [filePreview, setFilePreview] = useState<any>();
  const [location, setLocation] = useState<Location>({} as Location);
  const { data, loading } = useQuery<Query, QueryGetEventByIdArgs>(
    GET_EVENT_BY_ID,
    {
      variables: { eventId: id as string },
    }
  );

  useEffect(() => {
    if (!!data?.getEventById) {
      setEvent(data.getEventById);
      if (!!data.getEventById.banner) {
        setFilePreview(data.getEventById.banner);
      }
      if (!!data.getEventById.location) {
        setLocation(data.getEventById.location);
      }
    }
  }, [data]);

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    accept: 'image/*,video/*',
    // @ts-ignore
    action: (file) => {
      EventToEdit({
        ...eventToEdit,
        file,
      });
      previewImage(file);
    },
    showUploadList: false,
  };

  const previewImage = (file: any) => {
    var oFReader = new FileReader();
    oFReader.readAsDataURL(file);

    oFReader.onload = function (oFREvent) {
      setFilePreview(oFREvent?.target?.result);
    };
  };

  return loading ? (
    <Row justify={'center'} style={{ padding: '5em 3em' }}>
      <Spin size={'large'} />
    </Row>
  ) : (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
            activeBorderColor: colors.defaultBorderColor,
            // hoverBg: colors.white,
            // activeBg: colors.white,
          },
          Select: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
          },
        },
      }}
    >
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Row align={'middle'} justify={'space-between'}>
                  <Col>
                    <Breadcrumb>
                      <Breadcrumb.Item>All events</Breadcrumb.Item>
                      <Breadcrumb.Item>{event?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                  <Col>
                    <EventActions event={event as Event} />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginTop: 12 }}>
                <Text fontSize={16} fontWeight={600}>
                  Event information
                </Text>
              </Col>
              <Col span={24} style={{ marginTop: 10 }}>
                <Row gutter={[16, 20]}>
                  <Col xs={24} lg={12} style={{ display: 'grid' }}>
                    <Text fontSize={12} color={'black5'}>
                      Event title
                    </Text>
                    {!!eventToEdit?.id ? (
                      <Input
                        value={eventToEdit?.title ?? event?.title ?? ''}
                        onChange={(e) => {
                          EventToEdit({
                            ...eventToEdit,
                            title: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <Text fontSize={16}>{event?.title}</Text>
                    )}
                  </Col>
                  <Col xs={0} lg={12} />
                  <Col xs={24} lg={12} style={{ display: 'grid' }}>
                    <Text fontSize={12} color={'black5'}>
                      Event location
                    </Text>
                    {!!eventToEdit?.id ? (
                      <LocationSearch
                        location={location?.locationString}
                        setLocation={(val) => {
                          setLocation(val);
                          EventToEdit({ ...eventToEdit, location: val });
                        }}
                      />
                    ) : (
                      <Text fontSize={16}>{event?.location?.title}</Text>
                    )}
                  </Col>
                  <Col xs={0} lg={12} />
                  <Col xs={12} lg={6} style={{ display: 'grid' }}>
                    <Text fontSize={12} color={'black5'}>
                      Start date and time
                    </Text>
                    {!!eventToEdit?.id ? (
                      <DatePicker
                        value={
                          eventToEdit?.start
                            ? dayjs(eventToEdit.start)
                            : event?.start
                            ? dayjs(Number(event.start))
                            : null
                        }
                        onChange={(start) => {
                          EventToEdit({
                            ...eventToEdit,
                            start,
                          });
                        }}
                        style={{ width: '100%' }}
                        showTime={{ format: 'hh:mm a' }}
                        placeholder={'Select start date and time'}
                        format={'DD-MM-YYYY hh:mm a'}
                      />
                    ) : (
                      <Text fontSize={16}>
                        {event?.start
                          ? format(new Date(Number(event?.start)), 'PP hh:mm a')
                          : '-'}
                      </Text>
                    )}
                  </Col>
                  <Col xs={12} lg={6} style={{ display: 'grid' }}>
                    <Text fontSize={12} color={'black5'}>
                      End date and time (optional)
                    </Text>
                    {!!eventToEdit?.id ? (
                      <DatePicker
                        value={
                          eventToEdit?.end
                            ? dayjs(eventToEdit.end)
                            : event?.end
                            ? dayjs(Number(event.end))
                            : null
                        }
                        onChange={(end) => {
                          EventToEdit({
                            ...eventToEdit,
                            end,
                          });
                        }}
                        style={{ width: '100%' }}
                        showTime={{ format: 'hh:mm a' }}
                        placeholder={'Select end date and time'}
                        format={'DD-MM-YYYY hh:mm a'}
                      />
                    ) : (
                      <Text fontSize={16}>
                        {event?.end
                          ? format(new Date(Number(event?.end)), 'PP hh:mm a')
                          : '-'}
                      </Text>
                    )}
                  </Col>
                  <Col xs={0} lg={12} />
                  <Col xs={24} lg={12} style={{ display: 'grid' }}>
                    <Text fontSize={12} color={'black5'}>
                      Event description
                    </Text>
                    {!!eventToEdit?.id ? (
                      <Input.TextArea
                        value={
                          eventToEdit?.description ?? event?.description ?? ''
                        }
                        onChange={(e) => {
                          EventToEdit({
                            ...eventToEdit,
                            description: e.target.value,
                          });
                        }}
                        autoSize={{ minRows: 4, maxRows: 6 }}
                      />
                    ) : (
                      <Text fontSize={16}>{event?.description}</Text>
                    )}
                  </Col>
                  <Col xs={0} lg={12} />
                  <Col style={{ display: 'grid', gap: 4 }} span={12}>
                    <Text fontSize={12} color={'black5'}>
                      Banner image
                    </Text>
                    {!!eventToEdit?.id ? (
                      <Dragger {...uploadProps} style={{ padding: '0 8px' }}>
                        {filePreview && (
                          <img
                            id="blah"
                            src={filePreview}
                            alt=""
                            style={{
                              height: 300,
                              maxWidth: '100%',
                              objectFit: 'contain',
                              borderRadius: 4,
                            }}
                          />
                        )}
                      </Dragger>
                    ) : !!event?.banner ? (
                      <img
                        src={event?.banner}
                        alt={''}
                        style={{
                          height: 300,
                          maxWidth: '100%',
                          objectFit: 'contain',
                          borderRadius: 4,
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
}
