import {
  Breadcrumb,
  Card,
  Col,
  ConfigProvider,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tag,
} from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_USER_BY_ID,
  Query,
  QueryGetUserByIdArgs,
  User,
  UserRole,
  UserToEdit,
} from '../../../graphql';
import React, { useEffect, useMemo, useState } from 'react';
import { LocationSearch, PrimaryButton, Text } from '../../../components';
import { startCase, uniq } from 'lodash';
import { UserActions } from '.';
import {
  advocateActivities,
  advocateQuestions,
  ambassadorQuestions,
  colors,
  guardianQuestions,
  openUrlInNewTab,
  statusMap,
  storytellerQuestions,
} from '../../../utils';
import Dragger from 'antd/es/upload/Dragger';
import { UploadProps } from 'antd/es/upload';
import { CloseOutlined } from '@ant-design/icons';
import facebookIcon from '../../../assets/images/facebookIcon.png';
import instagramIcon from '../../../assets/images/instagramIcon.png';
import xIcon from '../../../assets/images/xIcon.png';
import behanceIcon from '../../../assets/images/behanceIcon.png';
import linkedInIcon from '../../../assets/images/linkedInIcon.png';
import tiktokIcon from '../../../assets/images/tiktokIcon.png';
import youtubeIcon from '../../../assets/images/youtubeIcon.png';

type Role = 'STORYTELLER' | 'GUARDIAN' | 'ADVOCATE' | 'AMBASSADOR';
type RoleType = 'storyteller' | 'guardian' | 'advocate' | 'ambassador';

export const urls = [
  { key: 'facebookUrl', icon: facebookIcon },
  { key: 'instagramUrl', icon: instagramIcon },
  { key: 'xUrl', icon: xIcon },
  { key: 'behanceUrl', icon: behanceIcon },
  { key: 'linkedInUrl', icon: linkedInIcon },
  { key: 'tiktokUrl', icon: tiktokIcon },
  { key: 'youtubeUrl', icon: youtubeIcon },
];

export default function SingleUser() {
  const { id } = useParams();
  const userToEdit = useReactiveVar(UserToEdit);
  const [otherActivity, setOtherActivity] = useState<string>('');
  const roles: Role[] = useMemo(
    () => ['STORYTELLER', 'GUARDIAN', 'ADVOCATE', 'AMBASSADOR'],
    []
  );
  const [user, setUser] = useState<User>();
  const { data, loading, refetch } = useQuery<Query, QueryGetUserByIdArgs>(
    GET_USER_BY_ID,
    {
      variables: { userId: id ?? '' },
    }
  );
  const [picturePreview, setPicturePreview] = useState<any>(null);
  const [bannerPreview, setBannerPreview] = useState<any>(null);

  useEffect(() => {
    if (!userToEdit) {
      setPicturePreview(null);
      setBannerPreview(null);
    }
  }, [userToEdit]);

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    accept: 'image/png,image/jpg,image/jpeg',
    showUploadList: false,
  };

  const pictureUploadProps: UploadProps = {
    ...uploadProps,
    // @ts-ignore
    action: (file) => {
      if (file) {
        UserToEdit({
          ...userToEdit,
          profile: { ...userToEdit?.profile, picture: file },
        });
        previewImage(file, 'picture');
      }
    },
  };

  const bannerUploadProps: UploadProps = {
    ...uploadProps,
    // @ts-ignore
    action: (file) => {
      UserToEdit({
        ...userToEdit,
        profile: { ...userToEdit?.profile, banner: file },
      });
      previewImage(file, 'banner');
    },
  };

  const previewImage = (file: any, type: 'picture' | 'banner') => {
    var oFReader = new FileReader();
    oFReader.readAsDataURL(file);

    oFReader.onload = function (oFREvent) {
      if (type === 'picture') setPicturePreview(oFREvent?.target?.result);
      else setBannerPreview(oFREvent?.target?.result);
    };
  };

  useEffect(() => {
    if (!!data?.getUserById) setUser(data.getUserById);
  }, [data]);

  const questions = useMemo(() => {
    switch (user?.role) {
      case 'STORYTELLER':
        return storytellerQuestions;
      case 'GUARDIAN':
        return guardianQuestions;
      case 'ADVOCATE':
        return advocateQuestions;
      case 'AMBASSADOR':
        return ambassadorQuestions;
      default:
        return {};
    }
  }, [user]);

  const Image = ({
    url,
    alt = '',
    height,
    width,
    aspectRatio,
    style,
  }: {
    url: string;
    alt?: string;
    height?: number | string;
    maxHeight?: number | string;
    width?: number | string;
    maxWidth?: number | string;
    aspectRatio?: number | string;
    style?: React.CSSProperties;
  }) => {
    const { marginTop = 0, ...restStyle } = style ?? {};

    return (
      <Card
        style={{ borderRadius: 4, marginTop, width: 'fit-content' }}
        bodyStyle={{ padding: 0 }}
      >
        <img
          src={url}
          alt={alt}
          style={{
            width,
            height,
            aspectRatio,
            borderRadius: 4,
            objectFit: 'cover',
            ...restStyle,
          }}
        />
      </Card>
    );
  };

  return loading ? (
    <Row justify={'center'} style={{ padding: '5em 3em' }}>
      <Spin size={'large'} />
    </Row>
  ) : !!user ? (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
            activeBorderColor: colors.defaultBorderColor,
            // hoverBg: colors.white,
            // activeBg: colors.white,
          },
          Select: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
          },
        },
      }}
    >
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Row align={'middle'} justify={'space-between'}>
                  <Col>
                    <Breadcrumb>
                      <Breadcrumb.Item>All users</Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Row align={'middle'} style={{ gap: 8 }}>
                          {user?.firstName + ' ' + user?.lastName}
                          <Tag color={statusMap[user.status]?.color}>
                            {statusMap[user.status]?.label}
                          </Tag>
                        </Row>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                  <Col>
                    <UserActions user={user as User} refetchUser={refetch} />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginTop: 12 }}>
                <Text fontSize={16} fontWeight={600}>
                  Account information
                </Text>
              </Col>
              <Col md={24} lg={12} style={{ marginTop: 10 }}>
                <Row gutter={[16, 20]}>
                  <Col style={{ display: 'grid' }} xs={24} md={12}>
                    <Text fontSize={12} color={'black5'}>
                      Full name
                    </Text>
                    {!!userToEdit?.id ? (
                      <Input
                        style={{ marginTop: 4 }}
                        value={
                          userToEdit?.name ??
                          user?.firstName + ' ' + user?.lastName
                        }
                        onChange={(e) =>
                          UserToEdit({ ...userToEdit, name: e.target.value })
                        }
                      />
                    ) : (
                      <Text fontSize={16}>
                        {user?.firstName + ' ' + user?.lastName}
                      </Text>
                    )}
                  </Col>
                  <Col style={{ display: 'grid' }} xs={24} md={12}>
                    <Text fontSize={12} color={'black5'}>
                      Email
                    </Text>
                    {!!userToEdit?.id ? (
                      <Input
                        style={{ marginTop: 4 }}
                        value={userToEdit?.email ?? user?.email}
                        onChange={(e) =>
                          UserToEdit({ ...userToEdit, email: e.target.value })
                        }
                      />
                    ) : (
                      <Text fontSize={16}>{user?.email}</Text>
                    )}
                  </Col>
                  <Col style={{ display: 'grid' }} xs={24} md={12}>
                    <Text fontSize={12} color={'black5'}>
                      User role
                    </Text>
                    {!!userToEdit?.id ? (
                      <Select
                        style={{ marginTop: 4 }}
                        value={userToEdit?.role ?? user?.role}
                        placeholder={'Role'}
                        onChange={(role) => UserToEdit({ ...userToEdit, role })}
                      >
                        {roles.map((role) => (
                          <Select.Option value={role} key={role}>
                            {startCase(role.toLowerCase())}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Text fontSize={16}>
                        {startCase(user?.role.toLowerCase())}
                      </Text>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Text fontSize={16} fontWeight={600}>
                  Basic information
                </Text>
              </Col>
              <Col md={24} lg={12} style={{ marginTop: 10 }}>
                <Row gutter={[16, 20]}>
                  <Col style={{ display: 'grid' }} xs={24}>
                    <Text fontSize={12} color={'black5'}>
                      Pronouns
                    </Text>
                    {!!userToEdit?.id ? (
                      <Radio.Group
                        value={
                          userToEdit?.profile?.pronouns ??
                          user?.profile?.pronouns ??
                          ''
                        }
                        onChange={(e) =>
                          UserToEdit({
                            ...userToEdit,
                            profile: {
                              ...userToEdit.profile,
                              pronouns: e.target.value,
                            },
                          })
                        }
                      >
                        <Row gutter={[0, 6]}>
                          <Col span={24}>
                            <Radio value={'he/him/his'}>he/him/his</Radio>
                          </Col>
                          <Col span={24}>
                            <Radio value={'she/her/hers'}>she/her/hers</Radio>
                          </Col>
                          <Col span={24}>
                            <Radio value={'they/them/theirs'}>
                              they/them/theirs
                            </Radio>
                          </Col>
                          <Col span={24}>
                            <Radio value={'other'}>Other</Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    ) : (
                      <Text fontSize={16}>
                        {user?.profile?.pronouns ?? '-'}
                      </Text>
                    )}
                  </Col>
                  <Col style={{ display: 'grid' }} span={24}>
                    <Text fontSize={12} color={'black5'}>
                      Location
                    </Text>
                    {!!userToEdit?.id ? (
                      <LocationSearch
                        options={{ types: ['(cities)'] }}
                        location={
                          userToEdit?.profile?.location ??
                          user.profile?.location ??
                          ''
                        }
                        setLocation={({ locationString, lat, long }) => {
                          UserToEdit({
                            ...userToEdit,
                            profile: {
                              ...userToEdit?.profile,
                              location: locationString,
                              lat: lat.toString(),
                              long: long.toString(),
                            },
                          });
                        }}
                      />
                    ) : (
                      <Text fontSize={16}>
                        {user?.profile?.location
                          ? JSON.parse(user.profile.location).label
                          : '-'}
                      </Text>
                    )}
                  </Col>
                  <Col style={{ display: 'grid' }} span={24}>
                    <Text fontSize={12} color={'black5'}>
                      Bio
                    </Text>
                    {!!userToEdit?.id ? (
                      <Input.TextArea
                        style={{ marginTop: 4 }}
                        value={
                          userToEdit?.profile?.bio ?? user?.profile?.bio ?? ''
                        }
                        onChange={(e) =>
                          UserToEdit({
                            ...userToEdit,
                            profile: {
                              ...userToEdit?.profile,
                              bio: e.target.value,
                            },
                          })
                        }
                        autoSize={{ minRows: 4, maxRows: 6 }}
                      />
                    ) : (
                      <Text fontSize={16}>{user?.profile?.bio ?? '-'}</Text>
                    )}
                  </Col>
                  <Col span={!!userToEdit?.id ? 24 : 12}>
                    <Row>
                      <Text fontSize={12} color={'black5'}>
                        Profile picture
                      </Text>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {!!userToEdit?.id ? (
                          <Dragger
                            {...pictureUploadProps}
                            style={{ padding: '0 8px' }}
                          >
                            {(picturePreview || !!user.profile?.picture) && (
                              <Image
                                url={picturePreview || user.profile?.picture}
                                style={{ maxHeight: 200, maxWidth: '100%' }}
                              />
                            )}
                          </Dragger>
                        ) : user.profile?.picture ? (
                          <Image
                            url={user.profile?.picture}
                            style={{
                              marginTop: 4,
                              maxHeight: 200,
                              maxWidth: '100%',
                              objectPosition: 'left',
                              objectFit: 'contain',
                            }}
                          />
                        ) : (
                          <Text fontSize={16}>-</Text>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={!!userToEdit?.id ? 24 : 12}>
                    <Row>
                      <Text fontSize={12} color={'black5'}>
                        Banner
                      </Text>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {!!userToEdit?.id ? (
                          <Dragger
                            {...bannerUploadProps}
                            style={{ padding: '0 8px' }}
                          >
                            {(bannerPreview || !!user.profile?.banner) && (
                              <Image
                                url={bannerPreview || user.profile?.banner}
                                style={{
                                  maxHeight: 200,
                                  maxWidth: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                            )}
                          </Dragger>
                        ) : user.profile?.banner ? (
                          <Image
                            url={user.profile?.banner}
                            style={{
                              marginTop: 4,
                              maxWidth: '100%',
                              maxHeight: 200,
                              objectPosition: 'left',
                              objectFit: 'contain',
                            }}
                          />
                        ) : (
                          <Text fontSize={16}>-</Text>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24} style={{ padding: 0 }}>
                    <Col style={{ display: 'grid' }} span={12}>
                      <Text fontSize={12} color={'black5'}>
                        Enter your website/portfolio URL
                      </Text>
                      {!!userToEdit?.id ? (
                        <Input
                          style={{ marginTop: 4 }}
                          value={
                            userToEdit?.profile?.websiteUrl ??
                            user?.profile?.websiteUrl ??
                            ''
                          }
                          onChange={(e) =>
                            UserToEdit({
                              ...userToEdit,
                              profile: {
                                ...userToEdit?.profile,
                                websiteUrl: e.target.value,
                              },
                            })
                          }
                        />
                      ) : (
                        <Text
                          fontSize={16}
                          className={!!user?.profile?.websiteUrl ? 'url' : ''}
                        >
                          {user?.profile?.websiteUrl ?? '-'}
                        </Text>
                      )}
                    </Col>
                  </Col>
                  <Col style={{ display: 'grid' }} span={12}>
                    <Text fontSize={12} color={'black5'}>
                      What company do you work for?
                    </Text>
                    {!!userToEdit?.id ? (
                      <Input
                        style={{ marginTop: 4 }}
                        value={
                          userToEdit?.profile?.companyName ??
                          user?.profile?.companyName ??
                          ''
                        }
                        onChange={(e) =>
                          UserToEdit({
                            ...userToEdit,
                            profile: {
                              ...userToEdit?.profile,
                              companyName: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      <Text fontSize={16}>
                        {user?.profile?.companyName ?? '-'}
                      </Text>
                    )}
                  </Col>
                  <Col style={{ display: 'grid' }} span={12}>
                    <Text fontSize={12} color={'black5'}>
                      What is your job title?
                    </Text>
                    {!!userToEdit?.id ? (
                      <Input
                        style={{ marginTop: 4 }}
                        value={
                          userToEdit?.profile?.jobTitle ??
                          user?.profile?.jobTitle ??
                          ''
                        }
                        onChange={(e) =>
                          UserToEdit({
                            ...userToEdit,
                            profile: {
                              ...userToEdit?.profile,
                              jobTitle: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      <Text fontSize={16}>
                        {user?.profile?.jobTitle ?? '-'}
                      </Text>
                    )}
                  </Col>

                  <Col style={{ display: 'grid' }} span={24}>
                    <Text fontSize={12} color={'black5'}>
                      Social media urls
                    </Text>
                    <Row style={{ marginTop: 4 }} gutter={[0, 14]}>
                      {urls.map((url) => (
                        <Col key={url.key} span={24}>
                          <Row
                            style={{
                              gap: !!userToEdit?.id ? 12 : 6,
                              flexWrap: 'nowrap',
                            }}
                            gutter={!!userToEdit?.id ? 12 : 6}
                            align={'middle'}
                          >
                            <img
                              src={url.icon}
                              alt={''}
                              style={{ width: 24, height: 24 }}
                            />
                            {!!userToEdit?.id ? (
                              <Input
                                style={{ width: '100%' }}
                                value={
                                  userToEdit?.profile?.[
                                    url.key as keyof typeof userToEdit.profile
                                  ] ??
                                  user?.profile?.[
                                    url.key as keyof typeof user.profile
                                  ] ??
                                  ''
                                }
                                onChange={(e) =>
                                  UserToEdit({
                                    ...userToEdit,
                                    profile: {
                                      ...userToEdit?.profile,
                                      [url.key as keyof typeof userToEdit.profile]:
                                        e.target.value,
                                    },
                                  })
                                }
                              />
                            ) : (
                              <Text
                                className={
                                  !!user?.profile?.[
                                    url.key as keyof typeof user.profile
                                  ]
                                    ? 'url'
                                    : ''
                                }
                                variant={'lgNormal'}
                              >
                                {user?.profile?.[
                                  url.key as keyof typeof user.profile
                                ] || '-'}
                              </Text>
                            )}
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </Col>

                  {user.role === UserRole.Storyteller && (
                    <Col style={{ display: 'grid' }} span={12}>
                      <Text fontSize={12} color={'black5'}>
                        User profile template
                      </Text>
                      {!!userToEdit?.id ? (
                        <Select
                          value={
                            userToEdit?.profile?.storyteller?.template ??
                            user?.profile?.storyteller?.template ??
                            ''
                          }
                          placeholder={'Select a template'}
                          onChange={(template) => {
                            UserToEdit({
                              ...userToEdit,
                              profile: {
                                ...userToEdit.profile,
                                storyteller: {
                                  ...userToEdit?.profile?.storyteller,
                                  template,
                                },
                              },
                            });
                          }}
                        >
                          {['A', 'B', 'C']?.map((template) => (
                            <Select.Option value={template}>
                              {template}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <Text fontSize={16}>
                          {user?.profile?.storyteller?.template ?? '-'}
                        </Text>
                      )}
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Text fontSize={16} fontWeight={600}>
                  Advanced information
                </Text>
              </Col>
              <Col span={12} style={{ marginTop: 12 }}>
                <Row gutter={[0, 20]}>
                  {Object.keys(questions).map((question) => (
                    <Col span={24} style={{ display: 'grid', gap: 2 }}>
                      <Text variant={'smNormal'} color={'black5'}>
                        {questions[question as keyof typeof questions]}
                      </Text>
                      {!!userToEdit?.id ? (
                        <Input.TextArea
                          style={{ marginTop: 4 }}
                          value={
                            // @ts-ignore
                            userToEdit?.profile?.[
                              user?.role.toLowerCase() as RoleType
                            ]?.[question] ??
                            // @ts-ignore
                            user?.profile?.[
                              user.role.toLowerCase() as RoleType
                            ]?.[question] ??
                            ''
                          }
                          onChange={(e) =>
                            UserToEdit({
                              ...userToEdit,
                              profile: {
                                ...userToEdit.profile,
                                [user?.role.toLowerCase() as RoleType]: {
                                  ...userToEdit?.profile?.[
                                    user?.role.toLowerCase() as RoleType
                                  ],
                                  [question]: e.target.value,
                                },
                              },
                            })
                          }
                          autoSize={{ minRows: 4, maxRows: 6 }}
                        />
                      ) : (
                        <Text variant={'lgNormal'}>
                          {/*@ts-ignore*/}
                          {user?.profile?.[user.role.toLowerCase()]?.[
                            question
                          ] ?? '-'}
                        </Text>
                      )}
                    </Col>
                  ))}

                  {user.role === UserRole.Storyteller && (
                    <Col span={24}>
                      <Row gutter={[0, 2]}>
                        <Col span={24}>
                          <Text variant={'smNormal'} color={'black5'}>
                            Upload up to 10 images
                          </Text>
                        </Col>
                        <Col span={24}>
                          {!!userToEdit?.id ? (
                            !!userToEdit.profile?.storyteller?.images
                              ?.length ? (
                              <Row gutter={[8, 8]}>
                                {userToEdit.profile?.storyteller?.images?.map(
                                  (image, index) => (
                                    <Col span={24} key={image}>
                                      <Row
                                        justify={'space-between'}
                                        align={'middle'}
                                      >
                                        <Col span={20}>
                                          <Row gutter={12} align={'middle'}>
                                            <Col>
                                              <Image
                                                url={image}
                                                alt={`image-${index}`}
                                                height={40}
                                                aspectRatio={1}
                                              />
                                            </Col>
                                            <Col style={{ maxWidth: '90%' }}>
                                              <Text
                                                variant={'lgNormal'}
                                                ellipsis
                                              >
                                                {image}
                                              </Text>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col>
                                          <CloseOutlined
                                            onClick={() => {
                                              UserToEdit({
                                                ...userToEdit,
                                                profile: {
                                                  ...userToEdit.profile,
                                                  storyteller: {
                                                    ...userToEdit?.profile
                                                      ?.storyteller,
                                                    images:
                                                      userToEdit.profile?.storyteller?.images?.filter(
                                                        (img) => img !== image
                                                      ),
                                                  },
                                                },
                                              });
                                            }}
                                            style={{
                                              fontSize: 18,
                                              color: colors.black3,
                                              cursor: 'pointer',
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  )
                                )}
                              </Row>
                            ) : (
                              <Text variant={'lgNormal'}>-</Text>
                            )
                          ) : !!user.profile?.storyteller?.images ? (
                            <Row gutter={[8, 8]}>
                              {user.profile?.storyteller?.images?.map(
                                (image, index) => (
                                  <Col style={{ width: '20%' }} key={image}>
                                    <Image
                                      url={image}
                                      alt={`image-${index}`}
                                      width={'100%'}
                                      aspectRatio={1}
                                    />
                                  </Col>
                                )
                              )}
                            </Row>
                          ) : (
                            <Text variant={'lgNormal'}>-</Text>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {user.role === UserRole.Storyteller && (
                    <Col span={24}>
                      <Row gutter={[0, 2]}>
                        <Col span={24}>
                          <Text variant={'smNormal'} color={'black5'}>
                            Enter up to 10 video urls (YouTube or Vimeo)
                          </Text>
                        </Col>
                        <Col span={24}>
                          {!!userToEdit?.id ? (
                            !!userToEdit.profile?.storyteller?.videoUrls
                              ?.length ? (
                              <Row gutter={[0, 8]}>
                                {userToEdit.profile?.storyteller?.videoUrls?.map(
                                  (videoUrl) => (
                                    <Col span={24} key={videoUrl}>
                                      <Row
                                        justify={'space-between'}
                                        align={'middle'}
                                      >
                                        <Col span={20}>
                                          <Text
                                            ellipsis
                                            variant={'lgNormal'}
                                            className={'url'}
                                            onClick={() =>
                                              openUrlInNewTab(videoUrl)
                                            }
                                          >
                                            {videoUrl}
                                          </Text>
                                        </Col>
                                        <Col>
                                          <CloseOutlined
                                            onClick={() => {
                                              UserToEdit({
                                                ...userToEdit,
                                                profile: {
                                                  ...userToEdit.profile,
                                                  storyteller: {
                                                    ...userToEdit?.profile
                                                      ?.storyteller,
                                                    videoUrls:
                                                      userToEdit.profile?.storyteller?.videoUrls?.filter(
                                                        (vid) =>
                                                          vid !== videoUrl
                                                      ),
                                                  },
                                                },
                                              });
                                            }}
                                            style={{
                                              fontSize: 18,
                                              color: colors.black3,
                                              cursor: 'pointer',
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  )
                                )}
                              </Row>
                            ) : (
                              <Text variant={'lgNormal'}>-</Text>
                            )
                          ) : !!user.profile?.storyteller?.videoUrls ? (
                            <Row gutter={[0, 8]}>
                              {user.profile?.storyteller?.videoUrls?.map(
                                (videoUrl) => (
                                  <Col span={24} key={videoUrl}>
                                    <Text
                                      ellipsis
                                      variant={'lgNormal'}
                                      className={'url'}
                                      onClick={() => openUrlInNewTab(videoUrl)}
                                    >
                                      {videoUrl}
                                    </Text>
                                  </Col>
                                )
                              )}
                            </Row>
                          ) : (
                            <Text variant={'lgNormal'}>-</Text>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {user.role === UserRole.Advocate && (
                    <Col span={24}>
                      <Row gutter={[0, 2]}>
                        <Col span={24}>
                          <Text variant={'smNormal'} color={'black5'}>
                            What are you doing in or around the ocean? (Select
                            all that apply)
                          </Text>
                        </Col>
                        <Col span={24} style={{ marginTop: 6 }}>
                          <Row gutter={[6, 6]}>
                            {!user?.profile?.advocate?.activities?.length ||
                            (!!userToEdit?.id &&
                              !userToEdit?.profile?.advocate?.activities
                                ?.length) ? (
                              <Col span={24}>
                                <Text variant={'lgNormal'}>-</Text>
                              </Col>
                            ) : (
                              uniq(
                                !!userToEdit?.id
                                  ? [
                                      ...advocateActivities,
                                      ...(userToEdit?.profile?.advocate
                                        ?.activities || []),
                                    ]
                                  : user?.profile?.advocate?.activities || []
                              ).map((activity) => (
                                <Col span={8} key={activity}>
                                  <Space
                                    onClick={() => {
                                      if (!!userToEdit?.id) {
                                        if (
                                          userToEdit?.profile?.advocate?.activities?.includes(
                                            activity
                                          )
                                        ) {
                                          UserToEdit({
                                            ...userToEdit,
                                            profile: {
                                              ...userToEdit?.profile,
                                              advocate: {
                                                ...userToEdit?.profile
                                                  ?.advocate,
                                                activities:
                                                  userToEdit?.profile?.advocate?.activities?.filter(
                                                    (item) => item !== activity
                                                  ),
                                              },
                                            },
                                          });
                                        } else {
                                          UserToEdit({
                                            ...userToEdit,
                                            profile: {
                                              ...userToEdit?.profile,
                                              advocate: {
                                                ...userToEdit?.profile
                                                  ?.advocate,
                                                activities: [
                                                  ...(userToEdit?.profile
                                                    ?.advocate?.activities ||
                                                    []),
                                                  activity,
                                                ],
                                              },
                                            },
                                          });
                                        }
                                      }
                                    }}
                                    className={
                                      !!userToEdit?.id
                                        ? `editable-activity-item${
                                            userToEdit?.profile?.advocate?.activities?.includes(
                                              activity
                                            )
                                              ? '-selected'
                                              : ''
                                          }`
                                        : 'activity-item'
                                    }
                                  >
                                    <Text className={'text'}>{activity}</Text>
                                  </Space>
                                </Col>
                              ))
                            )}
                            {!!userToEdit?.id && (
                              <Col span={24}>
                                <Row style={{ flexWrap: 'nowrap', gap: 6 }}>
                                  <Input
                                    placeholder={'Other'}
                                    value={otherActivity}
                                    onChange={(e) =>
                                      setOtherActivity(e.target.value)
                                    }
                                  />
                                  <PrimaryButton
                                    disabled={!otherActivity.length}
                                    onClick={() => {
                                      const activityToAdd =
                                        startCase(otherActivity);

                                      setOtherActivity('');
                                      UserToEdit({
                                        ...userToEdit,
                                        profile: {
                                          ...userToEdit?.profile,
                                          advocate: {
                                            ...userToEdit?.profile?.advocate,
                                            activities: [
                                              ...(userToEdit?.profile?.advocate
                                                ?.activities || []),
                                              activityToAdd,
                                            ],
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    Add
                                  </PrimaryButton>
                                </Row>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  ) : (
    <></>
  );
}
