import { Breadcrumb, Card, Col, ConfigProvider, Input, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Query,
  GroupToEdit,
  GET_GROUP_BY_ID,
  QueryGetGroupByIdArgs,
  Group,
} from '../../../graphql';
import { useEffect, useState } from 'react';
import { Text } from '../../../components';
import { GroupActions } from '.';
import { colors } from '../../../utils';
import Dragger from 'antd/es/upload/Dragger';
import { UploadProps } from 'antd/es/upload';

export default function SingleGroup() {
  const { id } = useParams();
  const groupToEdit = useReactiveVar(GroupToEdit);
  const [group, setGroup] = useState<Group>();
  const [filePreview, setFilePreview] = useState<any>();
  const { data, loading } = useQuery<Query, QueryGetGroupByIdArgs>(
    GET_GROUP_BY_ID,
    {
      variables: { groupId: id as string },
    }
  );

  useEffect(() => {
    if (!!data?.getGroupById) {
      setGroup(data.getGroupById);
      if (!!data.getGroupById.banner) {
        setFilePreview(data.getGroupById.banner);
      }
    }
  }, [data]);

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    accept: 'image/*,video/*',
    // @ts-ignore
    action: (file) => {
      GroupToEdit({
        ...groupToEdit,
        file,
      });
      previewImage(file);
    },
    showUploadList: false,
  };

  const previewImage = (file: any) => {
    var oFReader = new FileReader();
    oFReader.readAsDataURL(file);

    oFReader.onload = function (oFREvent) {
      setFilePreview(oFREvent?.target?.result);
    };
  };

  return loading ? (
    <Row justify={'center'} style={{ padding: '5em 3em' }}>
      <Spin size={'large'} />
    </Row>
  ) : (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
            activeBorderColor: colors.defaultBorderColor,
            // hoverBg: colors.white,
            // activeBg: colors.white,
          },
          Select: {
            colorBgContainer: colors.white,
            colorBorder: colors.defaultBorderColor,
          },
        },
      }}
    >
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Row align={'middle'} justify={'space-between'}>
                  <Col>
                    <Breadcrumb>
                      <Breadcrumb.Item>All groups</Breadcrumb.Item>
                      <Breadcrumb.Item>{group?.name}</Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                  <Col>
                    <GroupActions group={group as Group} />
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ marginTop: 12 }}>
                <Text fontSize={16} fontWeight={600}>
                  Group information
                </Text>
              </Col>
              <Col span={24} style={{ marginTop: 10 }}>
                <Row gutter={[16, 20]}>
                  <Col xs={24} lg={12} style={{ display: 'grid' }}>
                    <Text fontSize={12} color={'black5'}>
                      Group title
                    </Text>
                    {!!groupToEdit?.id ? (
                      <Input
                        value={groupToEdit?.name ?? group?.name ?? ''}
                        onChange={(e) => {
                          GroupToEdit({
                            ...groupToEdit,
                            name: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <Text fontSize={16}>{group?.name}</Text>
                    )}
                  </Col>
                  <Col xs={0} lg={12} />
                  <Col xs={24} lg={12} style={{ display: 'grid' }}>
                    <Text fontSize={12} color={'black5'}>
                      Group description
                    </Text>
                    {!!groupToEdit?.id ? (
                      <Input.TextArea
                        value={
                          groupToEdit?.description ?? group?.description ?? ''
                        }
                        onChange={(e) => {
                          GroupToEdit({
                            ...groupToEdit,
                            description: e.target.value,
                          });
                        }}
                        autoSize={{ minRows: 4, maxRows: 6 }}
                      />
                    ) : (
                      <Text fontSize={16}>{group?.description}</Text>
                    )}
                  </Col>
                  <Col xs={0} lg={12} />
                  <Col style={{ display: 'grid', gap: 4 }} span={12}>
                    <Text fontSize={12} color={'black5'}>
                      Banner image
                    </Text>
                    {!!groupToEdit?.id ? (
                      <Dragger {...uploadProps} style={{ padding: '0 8px' }}>
                        {filePreview && (
                          <img
                            id="blah"
                            src={filePreview}
                            alt=""
                            style={{
                              height: 300,
                              maxWidth: '100%',
                              objectFit: 'contain',
                              borderRadius: 4,
                            }}
                          />
                        )}
                      </Dragger>
                    ) : !!group?.banner ? (
                      <img
                        src={group?.banner}
                        alt={''}
                        style={{
                          height: 300,
                          maxWidth: '100%',
                          objectFit: 'contain',
                          borderRadius: 4,
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </ConfigProvider>
  );
}
