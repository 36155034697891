import { Card, Col, Radio, Row, Table } from 'antd';
import { Text } from '../../../components';
import { useQuery } from '@apollo/client';
import {
  Query,
  User,
  GET_ALL_USERS,
  QueryGetUsersArgs,
  GET_USERS_FILTER,
  UserRole,
} from '../../../graphql';
import { ColumnsType } from 'antd/es/table';
import { UserStatusCell } from '.';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';

const Users = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState<UserRole | 'all'>('all');
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<{ label: string; value: string }[]>(
    []
  );
  const { data, loading, refetch } = useQuery<Query, QueryGetUsersArgs>(
    GET_ALL_USERS,
    {
      variables: {
        page,
        take: 10,
        ...(!!role && role !== 'all' && { roles: [role] }),
      },
    }
  );
  const { data: filtersData, loading: filtersLoading } =
    useQuery<Query>(GET_USERS_FILTER);

  useEffect(() => {
    if (filtersData?.getUsersFilter) {
      const filters = [
        { label: `All users (${data?.getUsers.count})`, value: 'all' },
      ];

      filtersData.getUsersFilter.forEach((item) => {
        filters.push({
          label: `${item.role[0] + item.role.slice(1).toLowerCase() + 's'} (${
            item.count
          })`,
          value: item.role,
        });
      });

      setFilters(filters);
    }
  }, [filtersData]);

  const columns: ColumnsType<User> = [
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'firstName',
      render: (item, record) => record.firstName + ' ' + record.lastName,
    },
    {
      key: 'createdAt',
      title: 'Registered At',
      dataIndex: 'createdAt',
      render: (item) => format(new Date(item), 'PP'),
    },
    {
      key: 'updatedAt',
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (item) => format(new Date(item), 'PP'),
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      render: (item) => startCase(item.toLowerCase()),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      onCell: () => {
        return {
          onClick: (e) => e.stopPropagation(),
        };
      },
      render: (item, record) => (
        <UserStatusCell user={record} refetchUsers={refetch} />
      ),
    },
  ];

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Row align={'middle'} justify={'space-between'} gutter={[10, 12]}>
            <Col>
              <Text fontSize={20} fontWeight={500}>
                All users
              </Text>
            </Col>
            <Col>
              <Row>
                <Radio.Group
                  options={filters}
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                  optionType="button"
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            loading={filtersLoading || loading}
            columns={columns}
            dataSource={data?.getUsers?.data}
            rowClassName={'clickable'}
            onRow={(record) => ({
              onClick: () => navigate(`/users/${record.id}`),
            })}
            pagination={{
              hideOnSinglePage: true,
              current: page,
              pageSize: 10,
              total: data?.getUsers?.count,
              onChange: (page) => setPage(page),
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Users;
